<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Stock</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Stock en Almacen</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>REPORTE GENERAL DE STOCK</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>NOMBRE PRODUCTO:</strong></span>
          <AutoComplete
            :dropdown="true"
            field="descripcion"
            v-model="productoSelected"
            :suggestions="productosListadoFiltrado"
            @complete="buscarProducto($event)"
            placeholder="Escriba Nombre Producto"
          >
            <template #item="slotProps">
              <div>
                <strong>{{ slotProps.item.descripcion }}</strong
                ><span><strong> ** </strong></span>
                <!-- {{ slotProps.item.fabricas.nombre_proveedor
                  }}<span><strong> **</strong></span> -->
                {{ slotProps.item.fabrica_nombre }}
                <span><strong> ** </strong></span>
                <span
                  :class="
                    stockClass(
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad)
                        : 0
                    )
                  "
                  ><strong>{{
                    slotProps.item.stock.length > 0
                      ? sinDecimal(slotProps.item.stock[0].cantidad)
                      : 0
                  }}</strong></span
                >
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>MARCA: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre"
            v-model="laboratorioSelected"
            :suggestions="laboratoriosListadoFiltrado"
            @complete="buscarLaboratorio($event)"
            placeholder="Buscar Marcas"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.nombre }} -
                {{ slotProps.item.nombre_proveedor }}
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>PROVEEDOR: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre_completo"
            v-model="proveedorSelected"
            :suggestions="proveedoresListadoFiltrado"
            @complete="buscarProveedor($event)"
            placeholder="Buscar Proveedor"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>GRUPO: </strong></span>
          <Dropdown
            v-model="grupoSelected"
            :options="grupos"
            optionLabel="nombre"
            optionValue="id"
            :filter="true"
            filterBy="nombre"
            placeholder="Seleccione Grupo"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>PROCEDENCIA: </strong></span>
          <Dropdown
            v-model="procedenciaSelected"
            :options="procedencias"
            optionLabel="nombre"
            optionValue="id"
            :filter="true"
            filterBy="nombre"
            placeholder="Seleccione Procedencia"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>STOCK M&Iacute;NIMO: </strong></span>
          <InputNumber v-model="precio_minimo" :showButtons="true" :min="0" />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>STOCK M&Aacute;XIMO: </strong></span>
          <InputNumber v-model="precio_maximo" :showButtons="true" :min="0" />
        </div>
        <div class="field col-12 md:col-1">
          <span><strong>SIN STOCK: </strong></span><br />
          <Checkbox value="sin_stock" v-model="stock_cero" />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-primary mr-2"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>
    </div>
    <div
      class="card estilos-card mt-2"
      v-if="stocks.length > 0 && 'Producto Editar' in auth.user.permissions"
    >
      <div class="p-fluid formgrid grid align-items-center">
        <div class="field col-12 md:col-4 mt-2">
          <div class="p-inputgroup align-items-center">
            <Checkbox
              v-model="updatePrice"
              :binary="true"
              @change="porcentaje_update = updatePrice ? 0 : null"
              :disabled="stocks.length == 0"
            />
            <span class="ml-1"
              ><strong>ACTUALIZAR PRECIOS DE VENTA</strong></span
            >
          </div>
        </div>
        <div class="field col-12 md:col-3 mt-2">
          <div class="p-inputgroup align-items-center">
            <Checkbox
              v-model="redondear_precio"
              :binary="true"
              :disabled="!updatePrice"
            />
            <label for="redondear_precio" class="ml-1"
              ><strong>REDONDEAR PRECIO</strong></label
            >
          </div>
        </div>
        <div class="field col-12 md:col-2 mt-2">
          <InputNumber
            v-model="porcentaje_update"
            :showButtons="true"
            :min="-100"
            :max="100"
            :suffix="'%'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :disabled="!updatePrice"
            class="p-inputtext-sm"
          />
        </div>
        <div class="field col-12 md:col-3 mt-2">
          <Button
            icon="pi pi-refresh"
            label="ACTUALIZAR PRECIOS"
            class="p-button-success p-button-sm"
            style="font-weight: bold"
            :loading="enviando"
            :disabled="enviando || !updatePrice || !porcentaje_update"
            @click="updatePrice ? updatePrecioVenta() : null"
          />
        </div>
      </div>
    </div>
    <div
      class="card"
      v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
      v-show="false"
    >
      <table>
        <thead>
          <tr>
            <td><strong>TOTAL INVERSI&Oacute;N BRUTA: </strong></td>
            <td>{{ convertirNumeroGermanicFormat(sumaTotalInversion) }} Bs.</td>
          </tr>
          <tr>
            <td><strong>TOTAL VALOR VENTA: </strong></td>
            <td>
              {{ convertirNumeroGermanicFormat(sumaTotalValorVenta) }} Bs.
            </td>
          </tr>
          <tr>
            <td><strong>TOTAL UTILIDAD: </strong></td>
            <td>{{ convertirNumeroGermanicFormat(sumaTotalUtilidad) }} Bs.</td>
          </tr>
        </thead>
      </table>
    </div>

    <div class="mt-2">
      <DataTable
        ref="reporte_stock"
        key="id"
        :value="stocks"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Stock"
        selectionMode="single click"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Stock..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="PDF Stock"
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger"
                v-tooltip.top="'Exportar PDF Stock en Almacen'"
                :loading="generando_pdf"
                :disabled="generando_pdf"
                @click="generarPDF()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="EXCEL Exportar"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success mr-2"
                v-tooltip.top="'Exportar Stock en Almacen'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStock()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Stock DescargarEXCEL' in auth.user.permissions"
                label="EXCEL Control de Stock"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Exportar Control de Stock'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStockControl()"
              />
            </span>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <!--  <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column> -->
        <Column
          field="codigo_interno"
          header="CÓD"
          :sortable="true"
          style="font-size: 0.8em"
        ></Column>
        <Column
          field="codigo_origen"
          header="CÓD. ORIG."
          :sortable="true"
          style="font-size: 0.8em; font-weight: bold"
        ></Column>
        <!-- <Column field="sucursal" header="SUCURSAL" :sortable="true">
          {{ sucursal }}
        </Column> -->
        <Column field="laboratorio" header="MARCA" style="font-size: 0.8em" />
        <Column field="proveedor" header="PROVEEDOR" style="font-size: 0.8em" />

        <Column
          field="producto"
          header="DESCRIPCIÓN/PRODUCTO"
          style="font-size: 0.8em"
        />
        <Column field="nombre_grupo" header="GRUPO" style="font-size: 0.8em" />
        <Column
          field="nombre_procedencia"
          header="PROC."
          style="font-size: 0.8em"
        />
        <Column
          class="text-right"
          field="cantidad"
          header="STOCK"
          :sortable="true"
          style="font-size: 0.8em"
        >
          <template #body="{ data }">
            <div :class="claseEstado(data)">
              {{ sinDecimal(data.cantidad) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="precio_compra"
          header="P.COMPRA"
          :sortable="true"
          style="font-size: 0.8em"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_compra) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="precio_sugerido"
          header="P.VENTA"
          :sortable="true"
          style="font-size: 0.8em"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="precio_compra"
          header="T.COMPRA"
          style="font-size: 0.8em"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_compra * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="precio_sugerido"
          header="T.VENTA"
          style="font-size: 0.8em"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_sugerido * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="utilidad"
          header="UTILIDAD"
          style="font-size: 0.8em"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_sugerido * data.cantidad -
                    data.precio_compra * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          class="text-center"
          field="stock_minimo"
          header="STOCK MIN."
          :sortable="true"
          style="font-size: 0.8em"
        >
          <template #body="{ data }">
            <div>
              {{ data.stock_minimo == 0 ? "No Definido" : data.stock_minimo }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import StockService from "@/service/StockService";
import SucursalService from "@/service/SucursalService";
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import { sum } from "lodash";
import GrupoService from "@/service/GrupoService";
import ProcedenciaService from "@/service/ProcedenciaService";

export default {
  data() {
    return {
      stocks: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      enviando: false,
      stock_cero: false,
      exportando: false,
      generando_pdf: false,
      productoSelected: null,
      productosListadoFiltrado: [],
      precio_minimo: 0,
      precio_maximo: 0,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      laboratorioSelected: null,
      laboratoriosListadoFiltrado: [],
      proveedorSelected: null,
      proveedoresListadoFiltrado: [],
      numero_elementos: 0,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      grupoSelected: 0,
      grupos: [],
      procedenciaSelected: 0,
      procedencias: [],
      updatePrice: false,
      porcentaje_update: null,
      redondear_precio: false,
    };
  },
  stockService: null,
  sucursalService: null,
  productService: null,
  auth: null,
  grupoService: null,
  procedenciaService: null,
  created() {
    this.stockService = new StockService();
    this.sucursalService = new SucursalService();
    this.initBuscar();
    this.productService = new ProductService();
    this.auth = useAuth();
    this.grupoService = new GrupoService();
    this.procedenciaService = new ProcedenciaService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarGrupo();
    this.cargarProcedencia();
  },
  computed: {
    sumaTotalInversion() {
      return sum(
        this.stocks.map((item) => {
          return item.cantidad * item.precio_compra;
        })
      );
    },
    sumaTotalValorVenta() {
      return sum(
        this.stocks.map((item) => {
          return parseFloat(item.precio_sugerido) * parseFloat(item.cantidad);
        })
      );
    },
    sumaTotalUtilidad() {
      /* sumo el valor de this.sumaTotalValorVenta - this.sumaTotalInversion */
      return this.sumaTotalValorVenta - this.sumaTotalInversion;
    },
  },
  methods: {
    updatePrecioVenta() {
      this.enviando = true;
      let datos = {
        updatePrice: this.updatePrice ? true : false,
        redondear_precio: this.redondear_precio ? true : false,
        porcentaje: this.porcentaje_update,
        stocks: this.stocks,
      };
      this.$confirm.require({
        header: "Confirmación",
        message: "¿ESTÁ SEGURO DE ACTUALIZAR LOS PRECIOS DE VENTA?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "SI, ACTUALIZAR",
        rejectLabel: "NO, CANCELAR",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        discard: () => {
          this.enviando = false;
        },
        accept: () => {
          this.stockService
            .updatePrecioVenta(datos)
            .then((data) => {
              if (data.status == 400) {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: data.message,
                  life: 6000,
                });
                this.enviando = false;
              } else {
                this.enviando = false;
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: data.message,
                  life: 3000,
                });
                this.BuscarFiltro();
              }
            })
            .catch(() => {
              this.enviando = false;
            });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Información",
            detail: "Se canceló la operación",
            life: 3000,
          });
          this.enviando = false;
        },
      });
    },
    exportStockControl() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected ? this.sucursalSelected.id : null,
          producto: this.productoSelected,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          stock_cero: this.stock_cero.length > 0 ? true : false,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
          grupo_id: this.grupoSelected ? this.grupoSelected : null,
          procedencia_id: this.procedenciaSelected
            ? this.procedenciaSelected
            : null,
        };
        this.stockService.exportStockAllControl(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    /* rowClass(data) {
      return {
        "p-highlight": data.cantidad <= 0,
      };
    }, */
    incrementar_numero_elementos() {
      this.numero_elementos++;
      return this.numero_elementos;
    },
    buscarLaboratorio(event) {
      setTimeout(() => {
        this.stockService
          .buscarLaboratorioXNombre({ texto: event.query })
          .then((response) => {
            this.laboratoriosListadoFiltrado = [...response.laboratorios];
          });
      }, 250);
    },
    buscarProveedor(event) {
      setTimeout(() => {
        this.stockService
          .buscarProveedorXNombre({ texto: event.query })
          .then((response) => {
            this.proveedoresListadoFiltrado = [...response.proveedores];
          });
      }, 250);
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    generarPDF() {
      if (this.stocks.length > 0) {
        this.generando_pdf = true;
        let datos = {
          sucursal: this.sucursalSelected,
          stock_cero: this.stock_cero.length > 0 ? true : false,
          producto: this.productoSelected ? this.productoSelected.id : null,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
          grupo_id: this.grupoSelected,
          procedencia_id: this.procedenciaSelected,
        };
        this.stockService
          .imprimirReporte(datos)
          .then(() => {
            this.generando_pdf = false;
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: "Se generó el reporte correctamente",
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.generando_pdf = false;
          });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.stocks = [];
      this.enviando = true;
      this.cargarStocksSucursal();
    },
    cargarStocksSucursal() {
      let datos = {
        sucursal: this.sucursalSelected ? this.sucursalSelected.id : null,
        producto: this.productoSelected,
        precio_minimo: this.precio_minimo,
        precio_maximo: this.precio_maximo,
        stock_cero: this.stock_cero.length > 0 ? true : false,
        proveedor_id: this.proveedorSelected ? this.proveedorSelected.id : null,
        fabrica_id: this.laboratorioSelected
          ? this.laboratorioSelected.id
          : null,
        grupo_id: this.grupoSelected ? this.grupoSelected : null,
        procedencia_id: this.procedenciaSelected
          ? this.procedenciaSelected
          : null,
      };
      this.stockService
        .filtrarStockSucursal(datos)
        .then((data) => {
          this.stocks = data.stocks;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((element) => {
            this.sucursales.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarGrupo() {
      this.grupoService
        .getGrupoAll()
        .then((response) => {
          this.grupos = response.grupos;
          this.grupos.unshift({ id: 0, nombre: "TODOS" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarProcedencia() {
      this.procedenciaService
        .getProcedenciaAll()
        .then((response) => {
          this.procedencias = response.procedencias;
          this.procedencias.unshift({ id: 0, nombre: "TODOS" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportStock() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          producto: this.productoSelected,
          stock_cero: this.stock_cero,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
          grupo_id: this.grupoSelected,
          procedencia_id: this.procedenciaSelected,
        };
        this.stockService.exportStockAll(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
.estilos-card {
  border-radius: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0em;
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
}
</style>
